


import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider11.png`}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3>First Slide Title</h3>
            <p>Description for the first slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider12.png`}
            alt="Second slide"
          />
          <Carousel.Caption>
            {/* <h3>Second Slide Title</h3>
            <p>Description for the second slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/images/slider13.jpg`}
            alt="Third slide"
          />
          <Carousel.Caption>
            {/* <h3>Third Slide Title</h3>
            <p>Description for the third slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* After Carousel Section */}
      <div 
  className="after-carousel-section text-center mt-4" 
  style={{
    backgroundColor: 'papayawhip',
    padding: '30px',
    borderRadius: '10px',
    boxShadow: 'papayawhip 0px 8px 24px',
    border: '1px solid papayawhip',
  }}
>
  <h3 
    className="section-title" 
    style={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}
  >
    Secure Your Future with Smart Investments
  </h3>
  <p 
    className="section-description" 
    style={{ color: '#555', fontSize: '16px', marginBottom: '30px' }}
  >
    Take charge of your financial future by investing in top-rated, 0% commission direct Mutual Funds and NPS. Join thousands of investors achieving their long-term goals with India's leading stockbroker.
  </p>
  
  <div className="features mt-5">
    <div className="row">
      <div 
        className="col-md-4 feature-box" 
        style={{
          padding: '20px',
          border: '1px solid #ddd',
          borderRadius: '10px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px'
        }}
      >
        <h5 style={{ color: '#333', fontWeight: 'bold' }}>Start Small, Grow Big</h5>
        <p style={{ color: '#666', fontSize: '14px' }}>
          Begin your investment journey with as little as ₹500. Watch your wealth grow over time with consistent, high-quality investments.
        </p>
      </div>
      <div 
        className="col-md-4 feature-box" 
        style={{
          padding: '20px',
          border: '1px solid #ddd',
          borderRadius: '10px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px'
        }}
      >
        <h5 style={{ color: '#333', fontWeight: 'bold' }}>Expert Recommendations</h5>
        <p style={{ color: '#666', fontSize: '14px' }}>
          Receive personalized investment advice from industry experts to align your portfolio with your financial goals.
        </p>
      </div>
      <div 
        className="col-md-4 feature-box" 
        style={{
          padding: '20px',
          border: '1px solid #ddd',
          borderRadius: '10px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          marginBottom: '20px'
        }}
      >
        <h5 style={{ color: '#333', fontWeight: 'bold' }}>Comprehensive Insights</h5>
        <p style={{ color: '#666', fontSize: '14px' }}>
          Track and analyze your investments in real-time with our advanced reporting tools and dashboards.
        </p>
      </div>
    </div>
  </div>

  <div className="benefits mt-5">
    <h4 
      style={{ fontWeight: 'bold', color: '#333', marginBottom: '15px' }}
    >
      Why Invest with Us?
    </h4>
    <ul 
  className="benefits-list mx-auto" 
  style={{
    listStyleType: 'disc',
    padding: '0',
    textAlign: 'center',
    fontSize: '16px',
    color: '#555',
    display: 'inline-block'
  }}
>
  {['Zero commission on direct Mutual Funds and NPS',
    'Top-rated funds to maximize your returns',
    '100% transparent and secure investment platform',
    'Access to SIPs, Tax Saver Funds, and more'].map((item, index) => (
      <li 
        key={index} 
        style={{ 
          marginBottom: '10px', 
          textAlign: 'left', 
          display: 'flex', 
          justifyContent: 'center' 
        }}
      >
        {item}
      </li>
  ))}
</ul>

  </div>
  
  <div className="mt-4">
    <Button 
      className="custom-aqua-button m-3" 
      onClick={() => navigate('/invest-now')}
      style={{
        backgroundColor: 'papayawhip',
        color: 'black',
        border: '1px solid papayawhip',
        padding: '10px 20px',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
      }}
    >
      Start Investing Today
    </Button>

    <div style={{ marginTop: '10px' }}>
      <Button 
        id="btn" 
        onClick={() => navigate('/login')} 
        className="custom-aqua-button"
        style={{
          backgroundColor: 'papayawhip',
          color: 'black',
          border: '1px solid papayawhip',
          padding: '10px 20px',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}
      >
        Login
      </Button>
    </div>
  </div>

  <p 
    className="mt-3" 
    style={{ color: '#007bff', fontSize: '14px' }}
  >
    Don't have an account?{' '}
    <a 
      href="#!" 
      onClick={() => navigate('/signup')} 
      className="custom-login-link"
      style={{ textDecoration: 'underline', color: '#007bff' }}
    >
      Signup now
    </a>
  </p>
</div>


    </Layout>
  );
};

export default HomePage;

