import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Layout from './Layout';
import { useLocation, useNavigate } from 'react-router-dom'; // Use location and navigate

const SchemeDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {};

  const currentDate = new Date().toLocaleDateString();

  const handleBuyMoreClick = () => {
    // Navigate to the BuyMorePage with the current scheme details
    navigate('/buy-more', { state: { item } });
  };

  if (!item) {
    return <p>No scheme data available.</p>;
  }

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <Card className=" border mb-4">
              <Card.Body>
                <h5>{item.SchemeName}</h5>
                <h6 className="text-muted">{item.FundType}</h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={12}>
            <Card className=" border mb-4">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                  <h6 className="text-muted">
  <strong>P&L</strong>
</h6>
<p style={{ color: item.PL >= 0 ? 'green' : 'red' }}>
  <strong>
    ₹ {item.PL >= 0 ? `+${Number(item.PL).toFixed(2)}` : Number(item.PL).toFixed(2)} <br />
    {item.Percentage >= 0 ? `+${Number(item.Percentage).toFixed(2)}%` : `${Number(item.Percentage).toFixed(2)}%`}
  </strong>
</p>

                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <h6>XIRR</h6>
                    <Button id="btn" className="custom-aqua-button">
                      View 
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} sm={12}>
            <Card className=" border mb-4">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-muted">
                      <strong>Invested</strong>
                    </h6>
                    <p><strong>₹ {Number(item.amount).toFixed(2)}</strong></p>
                  </div>
                  <div>
                    <h6 className="text-muted">
                      <strong>Current</strong>
                    </h6>
                    <p><strong>₹ {Number(item.Currentvalue).toFixed(2)}</strong></p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={12}>
            <Card className="border mb-4">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-muted">
                      <strong>Avg. NAV</strong>
                    </h6>
                    <p><strong>₹ {Number(item.boughtNav).toFixed(2)}</strong></p>
                  </div>
                  <div>
                    <h6 className="text-muted">
                      <strong>Units</strong>
                    </h6>
                    <p><strong>{item.unitAllocated}</strong></p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} sm={12}>
            <Card className="border mb-4">
              <Card.Body>
                <h6 className="text-muted">
                  <strong>Current NAV ({currentDate})</strong>
                </h6>
                <p><strong>₹ {Number(item.curNav).toFixed(2)}</strong></p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-center">
              <Button id="btn" onClick={handleBuyMoreClick} className="custom-aqua-button">
                Buy More
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SchemeDetailsPage;
