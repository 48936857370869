import React, { useEffect, useState } from 'react';
import { Navbar, Container,Col,Row, Dropdown, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'react-feather';
import '../css/HomePage.css'; // Make sure to define the necessary styles here

const Layout = ({ children }) => {
  const navigate = useNavigate();
  
  const [loggedInEmail, setLoggedInEmail] = useState(null);
  const [initials, setInitials] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // For mobile dropdown

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail'); // Corrected key
    if (storedEmail) {
      setLoggedInEmail(storedEmail);

      // Extract the first two initials from the email
      const emailParts = storedEmail.split('@')[0];
      const emailInitials = emailParts.slice(0, 2).toUpperCase(); // Take the first two characters
      setInitials(emailInitials); 
    }

    // Detect mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="layout">
      <Navbar expand="lg" className="navbar-custom">
  <Container className="justify-content-between">
    <Navbar.Brand href="/">
      <img
        src={`${process.env.PUBLIC_URL}/images/logo.png`}
        alt="Logo"
        width="100"
        height="50"
        className="navbar-logo"
      />
    </Navbar.Brand>

    {loggedInEmail ? (
      <Dropdown align="end" show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="custom-aqua-button"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            // backgroundColor: '#007bff',
            backgroundColor : 'papayawhip',
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            fontFamily: "'Times New Roman', Times, serif", // Apply font-family inline
          }}
        >
          {initials}
        </Dropdown.Toggle>

        <Dropdown.Menu className="custom-dropdown-item">
          {/* <Dropdown.Item href="/portfolio">Portfolio</Dropdown.Item> */}
          <Dropdown.Item onClick={() => navigate('/portfolio')}>Portfolio</Dropdown.Item>

          {/* <Dropdown.Item href="/sips">SIPs</Dropdown.Item> */}
          <Dropdown.Item onClick={()=>navigate('/sips')}>SIPs</Dropdown.Item>
         

          {/* <Dropdown.Item href="/invest-now">Invest Now</Dropdown.Item> */}
          <Dropdown.Item onClick={()=>navigate('/invest-now')}>Invest Now</Dropdown.Item>

          {/* <Dropdown.Item href="/recommended-funds">Recommended Funds</Dropdown.Item> */}
          <Dropdown.Item onClick={()=>navigate('/recommended-funds')}>Recommended Funds</Dropdown.Item>

          {/* <Dropdown.Item href="/tax-saver-funds">Tax Saver Funds</Dropdown.Item> */}
          <Dropdown.Item onClick={()=>navigate('/tax-saver-funds')}>Tax Saver Funds</Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item onClick={() => navigate('/logout')}>Logout</Dropdown.Item>

         

        </Dropdown.Menu>
      </Dropdown>
    ) : (
      isMobile ? (
        <Dropdown align="end" show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
          <Dropdown.Toggle variant="link" id="mobile-menu" className="menu-icon-toggle">
            <Menu size={24} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="custom-dropdown-item" onClick={() => navigate('/login')}>Login</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Button
          variant="primary"
          className="custom-aqua-button custom-login-btn"
          onClick={() => navigate('/login')}
        >
          Login
        </Button>
      )
    )}
  </Container>
</Navbar>

      <main>{children}</main>
      <footer className="footer py-4">
  <Container>
    <Row>
      <Col md={4} className="text-center mb-3">
        <h6 className="footer-title">© 2024 AutoWealth.in</h6>
        <p>AMFI Reg No: 249603</p>
        <a href="/terms" className="footer-link">Terms & Conditions</a>
      </Col>

      <Col md={4} className="text-center mb-3">
        <h6 className="footer-title">About AutoWealth</h6>
        <p>
          AutoWealth helps you make smart investment decisions with ease. 
          Our platform provides personalized solutions for your financial goals.
        </p>
      </Col>

      <Col md={4} className="text-center mb-3">
        <h6 className="footer-title">Contact Us</h6>
        <p>Email: <a href="mailto:support@autowealth.in">support@autowealth.in</a></p>
        <p>Phone: +91 9341059619</p>

        <div className="social-media">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </Col>
    </Row>
  </Container>
</footer>

    </div>
  );
};

export default Layout;
