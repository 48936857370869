// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// import Layout from './Layout';
// import { useLocation, useNavigate } from 'react-router-dom';

// const BuyMorePage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { item } = location.state || {};
//   const [investmentAmount, setInvestmentAmount] = useState('');

//   const handleBuyMore = () => {
//     // Handle the buy logic here
//     console.log(`Buying more of ${item.SchemeName} with amount: ₹${investmentAmount}`);
    
//     // Redirect to portfolio page or success page after purchase
//     navigate('/portfolio');
//   };

//   if (!item) {
//     return <p>No scheme data available.</p>;
//   }

//   return (
//     <Layout>
//       <Container className="mt-5">
//         <Row>
//           <Col md={12}>
//             <Card className=" border mb-4">
//               <Card.Body>
//                 <h5>{item.SchemeName}</h5>
//                 <h6 className="text-muted">{item.FundType}</h6>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         <Row>
//           <Col md={12}>
//             <Card className="border mb-4">
//               <Card.Body>
//                 <Form>
//                   <Form.Group controlId="investmentAmount">
//                     <Form.Label>Investment Amount</Form.Label>
//                     <Form.Control
//                       type="number"
//                       placeholder="Enter amount"
//                       value={investmentAmount}
//                       onChange={(e) => setInvestmentAmount(e.target.value)}
//                     />
//                   </Form.Group>
//                 </Form>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>

//         <Row>
//           <Col md={12} className="text-center">
//             <Button id="btn" onClick={handleBuyMore} className="custom-aqua-button">
//               Buy
//             </Button>
//           </Col>
//         </Row>
//       </Container>
//     </Layout>
//   );
// };

// export default BuyMorePage;

import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Layout from './Layout';
import { useLocation, useNavigate } from 'react-router-dom';

const BuyMorePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {};
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [loading, setLoading] = useState(false); // To handle loading state
  const [error, setError] = useState(null); 

  const handleBuyMore = async () => {
    if (!investmentAmount || isNaN(investmentAmount) || investmentAmount <= 0) {
      alert("Please enter a valid investment amount.");
      return;
    }

    setLoading(true);
    setError(null); // Clear previous errors

    try {
      
      const api_path = process.env.REACT_APP_API_PATH;   
      const response = await fetch(`${api_path}buySell/mutualFundBuy.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          schemeName: item.SchemeName,
          fundType: item.FundType,
          amount: investmentAmount,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result);

      
      navigate('/portfolio', { state: { successMessage: 'Investment successful!' } });
    } catch (error) {
      console.error('Error while buying more:', error);
      setError('Failed to complete the transaction. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!item) {
    return <p>No scheme data available.</p>;
  }

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <Card className="border mb-4">
              <Card.Body>
                <h5>{item.SchemeName}</h5>
                <h6 className="text-muted">{item.FundType}</h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card className="border mb-4">
              <Card.Body>
                <Form>
                  <Form.Group controlId="investmentAmount">
                    <Form.Label><strong>Investment Amount</strong></Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      value={investmentAmount}
                      onChange={(e) => setInvestmentAmount(e.target.value)}
                      disabled={loading}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            {error && <p className="text-danger">{error}</p>}
            <Button
              id="btn"
              onClick={handleBuyMore}
              className="custom-aqua-button"
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Buy'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BuyMorePage;

