import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './authContext';
import Layout from './Layout';
import '../css/HomePage.css';

const LoginPage = () => {
  const [mobileOrEmail, setMobileOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  // Automatically remove alert after 3 seconds
  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError('');
        setSuccess('');
      }, 3000);
      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!mobileOrEmail || !password) {
      setError('Please enter both mobile/email and password.');
      return;
    }
  
    try {
      const api_Path = process.env.REACT_APP_API_PATH;
      console.log('API path:', `${api_Path}user_login/users.php`);
  
      const response = await axios.post(`${api_Path}user_login/users.php`, {
        UserName: mobileOrEmail,
        UserPass: password,
      },
    {
      withCredentials: true,
    });
  
      if (response.data.Success) {
        setSuccess('Login is successful!');
        setError('');
        const { userID, userEmail } = response.data.Message;
  
        console.log('Response:', response);
        console.log('User Email:', userEmail);
        console.log('User ID:', userID);
  
        localStorage.setItem('userEmail', userEmail);
        localStorage.setItem('userID', userID);
        localStorage.setItem('password', password);
  
        login();
        navigate('/portfolio');
      } else {
        setError(response.data.Message || 'Login failed. Please try again.');
        setSuccess('');
      }
    } catch (error) {
      console.error('API Error:', error);
      setError('Failed to connect to API.');
      setSuccess('');
    }
  };
  

  return (
    <Layout>
      <Container className="mt-5">
        <h3 className="text-center mb-4 ">Login</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form 
  onSubmit={handleSubmit} 
  className="p-4 border rounded shadow-sm bg-light"
>
  {/* <h4 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#007bff' }}>
    Login to Your Account
  </h4> */}
  <Form.Group controlId="formMobileOrEmail" className="mb-3">
    <Form.Label className="fw-bold">
      Mobile No. or Email Address<span style={{ color: 'red' }}>*</span>
    </Form.Label>
    <Form.Control
      type="text"
      placeholder="Enter mobile number or email address"
      value={mobileOrEmail}
      onChange={(e) => setMobileOrEmail(e.target.value)}
      className="rounded shadow-sm"
      required
    />
  </Form.Group>
  <Form.Group controlId="formPassword" className="mb-3">
    <Form.Label className="fw-bold">
      Password<span style={{ color: 'red' }}>*</span>
    </Form.Label>
    <Form.Control
      type="password"
      placeholder="Enter password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="rounded shadow-sm"
      required
    />
  </Form.Group>
  <div className="d-flex justify-content-center">
    <Button 
      id="btn" 
      type="submit" 
      className="custom-aqua-button rounded-pill px-5 fw-bold"
      style={{ backgroundColor: '#007bff', color: '#fff', border: 'none' }}
    >
      Login
    </Button>
  </div>
</Form>

        {/* <p className="mt-3 text-center">
          Not registered yet? <a href="/signup">Sign up here</a>
        </p> */}
{/* 
<p className="mt-3 text-center">
  Not registered yet?{' '}
  <a href="/signup" className="custom-signup-link">Sign up here</a>
</p> */}


<p className="mt-3 text-center">
  Not registered yet?{' '}
  <a 
    href="#!" 
    onClick={() => navigate('/signup')} 
    className="custom-signup-link"
  >
    Sign up here
  </a>
</p>

      </Container>
    </Layout>
  );
};

export default LoginPage;
