import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importing axios for API calls
import { Table } from 'react-bootstrap'; // Importing Bootstrap for styling
import Layout from './Layout';
import '../css/HomePage.css';

const SIPTable = () => {
  const [sipData, setSipData] = useState([]); // Initialize state for storing API data
  const [loading, setLoading] = useState(true); // State to track loading
  const [error, setError] = useState(null); // State to track errors
  const userId = localStorage.getItem('userID'); // Get userID from localStorage

  // useEffect to fetch SIP data from API
  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        setError('User ID not found');
        setLoading(false);
        return;
      }

      try {
        const api_Path = process.env.REACT_APP_API_PATH; 

        // Fetch portfolio data (example of another API call)
        const folioResponse = await axios.get(`${api_Path}Portfolio/getFolioData.php`, {
          withCredentials: true,
        });
        console.log('Folio Data:', folioResponse.data);

        // Fetch SIP data
        const sipResponse = await axios.get(`${api_Path}Sipdata/sipdata.php`, {
          params: { userId },
          withCredentials: true,
        });

        // Process the SIP data response
        if (sipResponse.data.Success && Array.isArray(sipResponse.data.Result)) {
          setSipData(sipResponse.data.Result);
        } else {
          setSipData([]); // Fallback if SIP data is not an array
        }

        setLoading(false); // Stop loading
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message); // Set error message
        setLoading(false); // Stop loading on error
      }
    };

    fetchData();
  }, [userId]); // Dependency array includes userId

  // Handle loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Handle error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="border sip-table-container-fluid">
        <h3 className="text-center m-4">Your SIPs</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Scheme Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sipData.length > 0 ? (
              sipData.map((sip, index) => (
                <tr key={index}>
                  <td>{sip.scheme}</td>
                  <td>{sip.achfromdate}</td>
                  <td>{sip.achtodate}</td>
                  <td>{sip.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No SIP data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default SIPTable;
